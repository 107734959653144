
import moment from "moment";
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import PosService from "../service/PosService.js";
import { useStore, ActionTypes } from "../store";


 interface itemList{
	 batchNo: string;
	 brandName: string;
	 categoryName: string;
	 freeUnit: number;
	 itemDescription: string;
	 itemDisc: number;
	 itemName: string;
	 mode: string;
	 mrp: number;
	 packSize: number;
	 posReceiptId: number;
	 productType: string;
	 purchaseDisc: number;
	 purchasePrice: number;
	 sectorName: string;
	 sellingPrice: number;
	 stockId: number;
	 subTotal: number;
	 supplierBonus: number;
	 tax1: number;
	 tax2: number;
	 tax3: number;
	 totalUnit: number;
	 unit: number;
 }


@Options({
	props: {
		PreviewReceipt: Object,
	},
	watch: {
		PreviewReceipt(obj) {
			
			this.openDialog();

			this.productDialog = obj.status;
		 
			if(obj.receiptID != 0)
			{
				this.loadReceipt(obj.receiptID);
			}
		},
	},
	emits: ["updatePreviewStatus"],
})


export default class PosPreviewReceipt extends Vue {

	private store = useStore();
	private toast;
	private productDialog = false;
	private transferStoreName = "";
	private showOnly = "Both";
	private posService;
	private selectedColumns =  [{header: 'Mode'}];

	private optionalListOptions = [
			{header: 'Mode'},
			{header: 'Batch NO'},
			{header: 'Brand Name'},
			{header: 'Brand Sector'},
			{header: 'Category'},
			{header: 'Product Type'},
			{header: 'Pack Size'},
			{header: 'Cus Disc'},
	];
	
	private optionalListOptionsTaxes = [
			{header: 'Mode'},
			{header: 'Batch NO'},
			{header: 'Brand Name'},
			{header: 'Brand Sector'},
			{header: 'Category'},
			{header: 'Product Type'},
			{header: 'Pack Size'},
			{header: 'Cus Disc'},
	];

	private itemList: itemList[] = [];

	private items = {
		storeName: "",
		storeAddress: "",
		storeEmail: "",
		storePhone: "",
		storeLicense: "",
		type: "",
		description: "",
		selectedProfile: "",
		billNo: "",
		discount: 0,
		paymentMethod: "",
		receiptDate: "",
		createdDate: "",
		receiptNo: "",
		returnReceipt: "",
		status: "",
		totalBill:0,
		totalChange:0,
		totalGrossAmt:0,
		totalPaid:0,
		totalTax:0,
		totalTax1:0,
		totalTax2:0,
		totalTax3:0,
		totalTendered:0,
	};

	private taxNames = [
		{
			taxName: "",
			show: false,
			optionalReq: "",
			taxValue: 0,
			accountHead: "",
			accountID: 0,
		},
		{
			taxName: "",
			show: false,
			optionalReq: "",
			taxValue: 0,
			accountHead: "",
			accountID: 0,
		},
		{
			taxName: "",
			show: false,
			optionalReq: "",
			taxValue: 0,
			accountHead: "",
			accountID: 0,
		},
	];

	//DEFAULT METHOD OF TYPE SCRIPT
	//CALLING WHENEVER COMPONENT LOADS
	created() {
		this.toast = new Toaster();
		this.posService = new PosService();
	}

	mounted()
	{
		const localList =  localStorage.getItem('optionalList');
		
		if(localList != null)
		{
			const parsedList = JSON.parse(localList);
			this.selectedColumns = parsedList;
		}
	}

	//OPEN DIALOG TO ADD NEW ITEM
	openDialog() {
		this.productDialog = true;
	}

	closeDialog() {
		this.$emit("updatePreviewStatus", {});
		this.productDialog = false;
	}

	getCompanyURL() 
	{
		return  require('@/assets/images/logo.png').default;
	}

	loadReceipt(receiptID)
	{
		this.posService.getReceiptData(receiptID).then((res) => {
			if (res != null) {

				if(res.tStoreDetails != null)
				{
					this.transferStoreName = res.tStoreDetails.transfer_branch.name;
				}
		 
				this.items.storeName         = res.storeDetail.name;
				this.items.storeAddress      = res.storeDetail.address;
				this.items.storeEmail        = res.storeDetail.email;
				this.items.storePhone        = res.storeDetail.contact;
				this.items.storeLicense      = res.storeDetail.license_no;
				this.items.description       = res.receipt.description;
				this.items.type              = res.receipt.type;
				this.items.selectedProfile   = res.receipt.profile_name.profileName;

				this.items.billNo             =  res.receipt.bill_no;
				this.items.discount           =  Number(res.receipt.discount);
				this.items.paymentMethod      =  res.receipt.payment_method;
				this.items.receiptDate        =  res.receipt.receipt_date;
				this.items.createdDate        =  res.receipt.created_at;
				this.items.receiptNo          =  res.receipt.receipt_no;
				this.items.returnReceipt      =  res.receipt.return_receipt;
				this.items.status             =  res.receipt.status;
				this.items.totalBill          =  Number(res.receipt.total_bill);
				this.items.totalChange        =  Number(res.receipt.total_change);
				this.items.totalGrossAmt      =  Number(res.receipt.total_gross_amt);
				this.items.totalPaid          =  Number(res.receipt.total_paid);
				this.items.totalTax           =  Number(res.receipt.total_tax);
				this.items.totalTax1          =  Number(res.receipt.total_tax1);
				this.items.totalTax2          =  Number(res.receipt.total_tax2);
				this.items.totalTax3          =  Number(res.receipt.total_tax3);
				this.items.totalTendered      =  Number(res.receipt.total_tendered);

				let vList = res.receiptList;
			 
				if(vList.length > 0)
				{
					this.itemList = [];

					vList.map(v => {
					 
						this.itemList.push({
							batchNo: v.batch_no,
							brandName: v.brand_name,
							categoryName: v.category_name,
							freeUnit: Number(v.free_unit),
							itemDescription: v.item_description,
							itemDisc: Number(v.item_disc),
							itemName: v.item_name,
							mode: v.mode,
							mrp: Number(v.mrp),
							packSize: Number(v.pack_size),
							posReceiptId: Number(v.pos_receipt_id),
							productType: v.product_type,
							purchaseDisc: Number(v.purchase_disc),
							purchasePrice: Number(v.purchase_price),
							sectorName: v.sector_name,
							sellingPrice: Number(v.selling_price),
							stockId: Number(v.stock_id),
							subTotal: Number(v.sub_total),
							supplierBonus: Number(v.supplier_bonus),
							tax1: Number(v.tax_1),
							tax2: Number(v.tax_2),
							tax3: Number(v.tax_3),
							totalUnit: Number(v.total_unit),
							unit: Number(v.unit),
					});
				});

				}
				
					//taxNames
					this.taxNames = [];

					this.taxNames.push({
						taxName: res.storeDetail.tax_name_1,
						show: res.storeDetail.show_1 == 'true' ? true : false,
						optionalReq: res.storeDetail.required_optional_1,
						taxValue:
							res.storeDetail.show_1 == "true"
								? Number(res.storeDetail.tax_value_1)
								: 0,
						accountHead: res.storeDetail.tax_name1.chartName,
						accountID: res.storeDetail.link1,
					});

					this.taxNames.push({
						taxName: res.storeDetail.tax_name_2,
						show: res.storeDetail.show_2  == 'true' ? true : false,
						optionalReq: res.storeDetail.required_optional_2,
						taxValue:
							res.storeDetail.show_2 == "true"
								? Number(res.storeDetail.tax_value_2)
								: 0,
						accountHead: res.storeDetail.tax_name2.chartName,
						accountID: res.storeDetail.link2,
					});

					this.taxNames.push({
						taxName: res.storeDetail.tax_name_3,
						show: res.storeDetail.show_3  == 'true' ? true : false,
						optionalReq: res.storeDetail.required_optional_3,
						taxValue:
							res.storeDetail.show_3 == "true"
								? Number(res.storeDetail.tax_value_3)
								: 0,
						accountHead: res.storeDetail.tax_name3.chartName,
						accountID: res.storeDetail.link3,
					});

					//CLEAR LIST AND RELOAD
					this.optionalListOptionsTaxes = [];
					this.optionalListOptionsTaxes = this.optionalListOptions;


					if(this.taxNames[0].show)
					{
						
						this.optionalListOptionsTaxes.push({header: this.taxNames[0].taxName});
					}

					if(this.taxNames[1].show)
					{
						this.optionalListOptionsTaxes.push({header: this.taxNames[1].taxName});
					}

					if(this.taxNames[2].show)
					{
						this.optionalListOptionsTaxes.push({header: this.taxNames[2].taxName});
					}
				}
		});
	}


	fixDigits(amt) {
		return Number(amt).toFixed(2);
	}

	formatDate(date)
	{
		return moment(date).format("DD-MM-YYYY");
	}
	
	expiryDate(date)
	{
		return moment(date).format("MMM-YYYY");
	}
	
	formatTime(date)
	{
		return moment(date).format("hh:mm A");
	}

	printReceipt() {
		window.print();
	}

	get receiptTypeName()
	{
		let title = '';

		if(this.items.type == 'INE')
		{
			title = 'Invoice Receipt';
		}
		else if(this.items.type == 'TRN')
		{
			title = 'Transfer Stock';
		}
		else if(this.items.type == 'PUR')
		{
			title = 'Purchase Receipt';
		}
		else if(this.items.type == 'RPU')
		{
			title = 'Purchase Return';
		}
		else if(this.items.type == 'RFD')
		{
			title = 'Return Receipt';
		}
		else
		{
			title = 'INVALID';
		}

		return title;
	}

	get receiptDialogName()
	{
		let title = '';

		if(this.items.type == 'INE')
		{
			title = 'Preview Invoice Receipt';
		}
		else if(this.items.type == 'TRN')
		{
			title = 'Preview Transfer Stock';
		}
		else if(this.items.type == 'PUR')
		{
			title = 'Preview Purchase Receipt';
		}
		else if(this.items.type == 'RPU')
		{
			title = 'Preview Purchase Return';
		}
		else if(this.items.type == 'RFD')
		{
			title = 'Preview Return Receipt';
		}
		else
		{
			title = 'INVALID';
		}
		
		return title;
	}

	onToggle(value) {
			this.selectedColumns = this.optionalListOptionsTaxes.filter(col => value.includes(col));
			localStorage.setItem('optionalList',JSON.stringify(this.selectedColumns));
	}

	checkOptionalCol(name: string)
	{
		const res = this.selectedColumns.filter(e => e.header == name);
		return res.length > 0 ? true : false;
	}

	get currency() {
		return this.store.getters.getCurrency;
	}
}
